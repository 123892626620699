import React from "react"
import PagesLayout from "../../layout/pages"
import { PageHeading } from "../../utils"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export const data = graphql`
  query mentorshipEditionsQuery($id: String) {
    wpMentorshipEdition(id: { eq: $id }) {
      name
      description
      customFields {
        status
        bannerflyer {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }

    allWpMentor(
      filter: {
        mentorshipEditions: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }
`

const DearDesignerMentors = ({ data }) => {
  const mentorshipEdition = data.wpMentorshipEdition
  const name = `DD Mentorship ${mentorshipEdition.name}`
  const image = getImage(mentorshipEdition.customFields.bannerflyer?.localFile)

  const mentors_list = data.allWpMentor.edges.map((mentor, index) => (
    <div key={index}>
      <GatsbyImage
        image={getImage(
          mentor.node.featuredImage.node?.localFile.childImageSharp
        )}
        alt={mentor.node.title}
        className="block "
      />
      <p className="mt-2 md:text-p14 text-p12">{mentor.node.title}</p>
      <p
        className="-mt-9 md:text-p12 text-[10px]"
        dangerouslySetInnerHTML={{ __html: mentor.node.content }}
      ></p>
    </div>
  ))

  return (
    <PagesLayout>
      <GatsbyImage image={image} alt={name} className=" mt-12 mb-8" />
      <PageHeading text={name} />
      <p>{mentorshipEdition.description}</p>

      <h4 className="mt-16 mb-8">Mentors</h4>
      <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-4 gap-6">
        {mentors_list}
      </section>
    </PagesLayout>
  )
}

export default DearDesignerMentors
